import _toConsumableArray from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import form from 'jquery-form';
import moment from 'moment';
import params from '@/assets/json/params.json';
import { isAdmin as _isAdmin } from '../../whiteList.js';
export default {
  data: function data() {
    var _this = this,
        _ref;

    return _ref = {
      okButtonProps: {
        props: {
          loading: false
        }
      },
      fileList: [],
      commandFormShow: false,
      keyboard: false,
      wrapperCol: {
        span: 10
      },
      labelCol: {
        span: 6
      },
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      columns: [{
        title: '电站名称',
        dataIndex: 'name',
        width: 120,
        scopedSlots: {
          customRender: '电站名称'
        },
        ellipsis: true
      }, {
        title: '地址',
        dataIndex: 'address',
        width: 160,
        scopedSlots: {
          customRender: '地址'
        },
        ellipsis: true
      }, {
        title: '最大运行功率',
        dataIndex: 'maxPower',
        width: 120,
        scopedSlots: {
          customRender: '最大运行功率'
        },
        customRender: function customRender(maxPower) {
          return maxPower == null ? '0 kW' : maxPower + ' kW';
        }
      }, {
        title: '设计容量',
        dataIndex: 'capacity',
        width: 80,
        scopedSlots: {
          customRender: '设计容量'
        },
        customRender: function customRender(capacity) {
          return capacity == null ? '0 kWh' : capacity + ' kWh';
        }
      }, {
        title: '建站时间',
        dataIndex: 'commissioningTime',
        width: 90,
        scopedSlots: {
          customRender: '建站时间'
        },
        customRender: function customRender(commissioningTime) {
          return commissioningTime.split(" ")[0];
        }
      }, {
        title: '售后电话',
        dataIndex: 'afterSalesCall',
        width: 100,
        scopedSlots: {
          customRender: '售后电话'
        }
      }, {
        title: '投运状态',
        dataIndex: 'isNormalRun',
        width: 85,
        scopedSlots: {
          customRender: 'isNormalRun'
        }
      }, {
        title: '备注',
        dataIndex: 'describe',
        width: 120,
        scopedSlots: {
          customRender: '备注'
        }
      }, {
        title: '操作',
        dataIndex: 'action',
        width: 200,
        scopedSlots: {
          customRender: 'action'
        }
      }],
      dateFormat: 'YYYY-MM-DD',
      scroll: {
        y: 660
      },
      datePickerLocale: datePickerLocale,
      selectedRows: [],
      selectedRowKeys: [],
      paramForm: {
        type: 1,
        equipType: null,
        parentEquipId: null,
        equipId: null,
        textData: null,
        param: null
      },
      settingParams: params,
      param: {
        util: "",
        upper: 0,
        lower: 0,
        discript: []
      },
      equipmentData: [],
      targetDevices: [],
      typeId: null,
      formLabelWidth: '120px',
      upgradeFormShow: false,
      dialogTitle: '',
      offset: null,
      transferProps: {
        key: 'id',
        label: 'name'
      },
      treeProps: {
        label: "name",
        children: "children"
      },
      showClose: false,
      labelPosition: 'right',
      center: {
        lng: 108.85960816629647,
        lat: 34.20053283852483
      },
      zoom: 6,
      stationForm: {
        id: null,
        name: null,
        address: null,
        city: null,
        customer: null,
        longitude: null,
        latitude: null,
        commissioningTime: null,
        afterSalesCall: null,
        powerSupply: null,
        classification: '1',
        operatingModel: '1',
        description: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请填写电站名称！',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请在右边地图中选择具体地址！',
          trigger: 'blur'
        }],
        templateId: [{
          required: true,
          message: '请选择模板',
          trigger: 'blur'
        }],
        maxPower: [{
          required: true,
          message: '请填写最大运行功率！',
          trigger: 'blur'
        }],
        customer: [{
          required: true,
          message: '请填写客户名称（最多10个汉字）',
          trigger: 'blur'
        }],
        capacity: [{
          required: true,
          message: '请填写设计容量！',
          trigger: 'blur'
        }],
        powerSupply: [{
          required: true,
          message: '请填写供电单位！',
          trigger: 'blur'
        }],
        classification: [{
          required: true,
          message: '请选择用电类别！',
          trigger: 'blur'
        }],
        operatingModel: [{
          required: true,
          message: '请选择运营模式！',
          trigger: 'blur'
        }],
        afterSalesCall: [{
          required: true,
          message: '请填写售后电话！',
          trigger: 'blur'
        }],
        commissioningTime: [{
          required: true,
          message: '请选择建站日期！',
          trigger: 'blur'
        }]
      },
      length: 1000000000,
      page: 1,
      tableData3: [],
      total: 0,
      stationId: -1,
      loading: false,
      FormVisible: false
    }, _defineProperty(_ref, "formLabelWidth", '95px'), _defineProperty(_ref, "operationType", 0), _defineProperty(_ref, "time", null), _defineProperty(_ref, "defaultProps", {
      children: 'children',
      label: 'label'
    }), _defineProperty(_ref, "mp", null), _defineProperty(_ref, "local", null), _defineProperty(_ref, "paramLoading", false), _ref;
  },
  computed: {
    rowSelection: function rowSelection() {
      var _this2 = this;

      return {
        //onChange方法，rowSelection改变时触发
        onChange: function onChange(selectedRowKeys, list) {
          //获取选中行的key元素，和下面selectedRowKeys属性配合使用，表示给rowSelection绑定一个v-model，为this.selectedRowKeys
          _this2.selectedRowKeys = selectedRowKeys; //获取选中的行的所有元素

          _this2.selectedRows = list;
        },
        selectedRowKeys: this.selectedRowKeys
      };
    }
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.loginUserId = this_.$store.state.userId;
    this_.getStationList();
  },
  methods: {
    isAdmin: function isAdmin() {
      return _isAdmin();
    },
    clearRowSelection: function clearRowSelection() {
      //将选中行的所有元素置空
      this.selectedRows = []; //将选中行的keys置空（即清空选中状态）

      this.selectedRowKeys = [];
    },
    cacheStations: function cacheStations() {
      var this_ = this;
      axios({
        method: 'post',
        url: '/emind/real/data/cache/stations'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.success('服务器更新设备缓存列表成功');
        } else {
          this_.$message.warning('服务器更新设备缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    equipChange: function equipChange() {
      var this_ = this;
      this_.paramForm.equipType = null;
    },
    paramChange: function paramChange() {
      var this_ = this;
      this_.param = this_.settingParams[this_.paramForm.param];
    },
    clearDebugDatas: function clearDebugDatas() {
      var this_ = this;
      var id = null;

      if (this.FormVisible) {
        return;
      }

      if (this_.selectedRows.length != 1) {
        this.$message.warning('您还没有选择要清除调试数据的电站，请先选择！');
        return;
      } else {
        id = this_.selectedRows[0].id;
      }

      this_.$confirm({
        title: "清除调试数据",
        content: '此操作将清除所选电站的调试数据, 是否继续?',
        okText: '确认',
        cancelText: '取消',
        onOk: function onOk() {
          axios({
            method: "delete",
            url: "/emind/history/data/debug/datas/" + id
          }).then(function (d) {
            //
            if (!d) {
              this_.$message.error('登录超时，请重新登录', 2, function () {});
              return;
            }

            if (d.data.status == '2000') {
              this_.$message.success('成功清除调试数据');
            } else if (d.data.status == '4003') {
              this_.$message.success('登录用户没有操作权限，请联系管理员。');
            } else if (d.data.status == '6001') {
              this_.$message.success('操作失败，请联系管理员。');
            } else {
              this_.$message.success('操作失败，请联系管理员。');
            }

            this_.clearRowSelection();
          }).catch(function (error) {
            if (error.message != '') {
              this_.$message.error(error.message, 2, function () {});
              return;
            }
          });
        }
      });
    },
    search: function search() {
      if (this.local != null) {
        this.local.search(this.stationForm.address);
      }
    },
    updateStationRunStatus: function updateStationRunStatus(record) {
      var this_ = this;
      var data = {
        id: record.id,
        name: record.name,
        commissioningTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        isNormalRun: record.isNormalRun == '1'
      };
      axios({
        method: 'put',
        url: '/emind/config/station/modification',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === "0") {
          this_.cacheStations();
          this_.$message.success('电站投运状态设置成功！');
        } else {
          this_.$message.warning('电站投运状态设置失败！');
          record.isNormalRun = data.isNormalRun ? '1' : '0';
        }
      }).catch(function (error) {
        if (error.message != '') {
          record.isNormalRun = data.isNormalRun ? '1' : '0';
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    toElectricityPrice: function toElectricityPrice(record) {
      var timePrice = {
        id: record.timePriceId,
        stationId: record.id,
        stationName: record.name
      };
      localStorage.setItem("timePrice", JSON.stringify(timePrice));
      var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
      var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {
        return key.indexOf('date') >= 0 ? new Date(value) : value;
      });
      breadcrumbs.push({
        name: "时段电价",
        url: ""
      });
      this.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
        root: true
      });
      this.$router.push("/electricity_price");
    },
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      return false;
    },
    uploadSectionFile: function uploadSectionFile() {
      var this_ = this;

      if (this.fileList.length == 0) {
        this.$message.warning("请选择升级文件");
        return;
      }

      if (this.fileList[0].name.indexOf(",") != -1) {
        this.$message.warning("文件名中不能含有‘,’(逗号)");
        return;
      }

      if (this.typeId == null) {
        this.$message.warning("请选择设备类型");
        return;
      }

      this_.okButtonProps.props.loading = true;
      var formData = new FormData();
      formData.append('file', this.fileList[0]);
      axios.post('/emind/config/equipment/upgrade/file/' + this.typeId + '/' + this.stationId, formData, {
        timeout: 300000
      }).then(function (d) {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code == 0) {
          this_.dialogFormVisible = false;
          this_.$message.success("升级文件上传成功");
        } else if (d.data.code == 1) {
          this_.dialogFormVisible = false;
          this_.$message.warning("升级文件与设备类型不匹配");
        } else if (d.data.code == 2) {
          this_.dialogFormVisible = false;
          this_.$message.warning("升级文件内容错误");
        } else if (d.data.code == -1) {
          this_.dialogFormVisible = false;
          this_.$message.error("升级文件上传失败");
        } else if (d.data.code === '401') {
          this_.dialogFormVisible = false;
          this_.$message.warning('当前账户没有升级设备的操作权限');
        }

        this_.okButtonProps.props.loading = false;
      }).catch(function (error) {
        this_.okButtonProps.props.loading = false;
        this_.dialogFormVisible = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    beforeCloseHandel1: function beforeCloseHandel1() {
      this.upgradeFormShow = false;
      this.typeId = null;
      this.fileList = [];
      this.percentage = 0;
    },
    updateSoftware: function updateSoftware(station) {
      this.stationId = station.id;
      this.percentage = 80;
      this.upgradeFormShow = true;
    },
    getequipmentData: function getequipmentData() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/list/2/' + this_.stationId
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code == 0) {
          if (d.data.data.length > 0) {
            this_.equipmentData = d.data.data[0].children;
          }
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    setParam: function setParam(station) {
      var this_ = this;
      this_.stationId = station.id;
      this_.getequipmentData();
      this_.commandFormShow = true;
      this_.paramForm = {
        type: 1,
        equipType: null,
        parentEquipId: null,
        equipId: null,
        textData: null,
        param: 0
      };
      this_.param = this_.settingParams[0];
    },
    parentEquipChange: function parentEquipChange() {
      var this_ = this;
      this.equipmentData.map(function (item) {
        if (item.id == this_.paramForm.parentEquipId) {
          this_.targetDevices = item.children;
        }
      });
      this_.paramForm.equipId = null;
    },
    equipTypeChange: function equipTypeChange() {
      var this_ = this;

      if (this.paramForm.equipType == 2) {
        this.targetDevices = this.equipmentData;
      } else if (this.paramForm.equipType == 3 || this.paramForm.equipType == 7 || this.paramForm.equipType == 8) {
        this.equipmentData.map(function (item) {
          if (item.id == this_.paramForm.parentEquipId) {
            this_.targetDevices = this_.equipmentData.children;
          }
        });
      }

      this_.paramForm.parentEquipId = null;
      this_.paramForm.equipId = null;
    },
    beforeCloseHandel2: function beforeCloseHandel2() {
      this.commandFormShow = false;
      this.paramForm = {
        type: 1,
        equipType: null,
        command: null,
        parentEquipId: null,
        equipId: null
      };
    },
    setParams: function setParams() {
      var this_ = this;
      var obj = {
        "stationId": this_.stationId,
        "id": this_.paramForm.equipId,
        "type": this_.paramForm.type,
        'equipType': this_.paramForm.equipType,
        'code': this_.param.code,
        'upper': this_.param.upper,
        'lower': this_.param.lower,
        'data': this_.param.data
      };

      if (this_.param.code == 'customize') {
        obj.data = 0;
        obj.code = this_.paramForm.command;
      }

      if (typeof obj.data == 'undefined') {
        if (typeof this_.param.descript == 'undefined') {
          this.$message.warning('您还没有选择设置参数，请选择！');
        } else if (this_.param.descript.length > 0) {
          this.$message.warning('您还没有输入设置参数的值，请输入！');
        } else {
          this.$message.warning('您还没有选择设置参数的值，请选择！');
        }

        return;
      }

      if (this_.paramForm.equipType != 1 && this_.paramForm.equipId == null) {
        this.$message.warning('您还没有指定设备，请先指定设备');
        return;
      }

      this_.paramLoading = true;
      axios.post('/emind/config/equipment/publish/command', obj, {
        timeout: 10000
      }).then(function (d) {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code == 0) {
          this_.$message.success("命令下发成功");
        } else if (d.data.code === '401') {
          this_.$message.warning('当前账户没有下发命令的操作权限');
        } else {
          this_.$message.error("命令下发失败");
        }

        this_.paramLoading = false;
        this_.commandFormShow = false;
      }).catch(function (error) {
        this_.paramLoading = false;
        this_.commandFormShow = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    submitForm: function submitForm(formName) {
      var this_ = this;
      this_.$refs[formName].validate(function (valid) {
        if (valid) {
          this_.okFn();
        }
      });
    },
    initMap: function initMap(station) {
      var this_ = this;
      this_.mp = new BMap.Map("map");
      var point;

      if (station == null || station.longitude == null || station.latitude == null) {
        point = new BMap.Point(this_.center.lng, this_.center.lat);
      } else {
        point = new BMap.Point(station.longitude, station.latitude);
      }

      this_.mp.addOverlay(new BMap.Marker(point));
      this_.mp.centerAndZoom(point, this_.zoom);
      this_.mp.enableScrollWheelZoom(true);
      this_.mp.setMapStyleV2({
        styleId: '9b3a6490a7d226175af527443727c56c'
      });
      this_.local = new BMap.LocalSearch(this_.mp, {
        renderOptions: {
          map: this_.mp,
          autoViewport: true
        }
      });
      this_.local.setSearchCompleteCallback(function (results) {
        // 判断状态是否正确
        if (this_.local.getStatus() == BMAP_STATUS_SUCCESS) {
          var str = "<ul>";

          for (var i = 0; i < results.getCurrentNumPois(); i++) {
            var poi = results.getPoi(i);
          }
        }
      });
      this_.mp.addEventListener("click", function (e) {
        this_.stationForm.longitude = e.point.lng; //通过  e.point.lng获取经度

        this_.stationForm.latitude = e.point.lat; //通过  e.point.lat获取纬度

        this_.zoom = e.target.getZoom();
        var geocoder = new BMap.Geocoder(); //创建地址解析器的实例

        geocoder.getLocation(e.point, function (rs) {
          this_.stationForm.city = rs.addressComponents.city;
          this_.stationForm.address = rs.address;
        });
      });
    },
    //获取刷新列表数据
    getStationList: function getStationList() {
      var this_ = this;
      this_.loading = true;
      axios({
        method: "post",
        url: "/emind/config/station/search",
        data: {
          startIndex: (this_.page - 1) * this_.length,
          length: this_.length
        }
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.total = d.data.recordsTotal;
        this_.tableData3 = d.data.data;
        var i = 0;
        this_.tableData3.map(function (item) {
          item.key = i++;
          item.isNormalRun = item.isNormalRun ? '1' : '0';
        });
        this_.loading = false;
      }).catch(function (error) {
        this_.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    okFn: function okFn() {
      var this_ = this;
      var user;
      var data = {
        id: this_.stationForm.id,
        name: this_.stationForm.name,
        address: this_.stationForm.address,
        city: this_.stationForm.city,
        customer: this_.stationForm.customer,
        longitude: this_.stationForm.longitude,
        latitude: this_.stationForm.latitude,
        afterSalesCall: this_.stationForm.afterSalesCall,
        powerSupply: this_.stationForm.powerSupply,
        classification: this_.stationForm.classification,
        operatingModel: this_.stationForm.operatingModel,
        description: this_.stationForm.description
      };
      data.commissioningTime = this_.stationForm.commissioningTime.format("YYYY-MM-DD HH:mm:ss");

      if (this_.operationType == 1) {
        axios({
          method: 'post',
          url: '/emind/config/station/creation',
          data: data
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === "0") {
            this_.$message.success('新建电站成功！');
            this_.operationType = 0;
            this_.FormVisible = false;
            this_.stationForm = {
              id: null,
              name: null,
              address: null,
              longitude: null,
              latitude: null,
              city: null,
              customer: null,
              commissioningTime: null,
              afterSalesCall: null,
              powerSupply: null,
              classification: '1',
              operatingModel: '1',
              description: ''
            };
            this_.cacheStations();
          } else {
            this_.$message.warning('新建电站失败！');
          }

          setTimeout(function () {
            this_.getStationList();
          }, 200);
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this_.operationType == 2) {
        axios({
          method: 'put',
          url: '/emind/config/station/modification',
          data: data
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === "0") {
            this_.$message.success('电站更新成功！');
            this_.operationType = 0;
            this_.FormVisible = false;
            this_.clearRowSelection();
            this_.cacheStations();
          } else {
            this_.$message.warning('电站更新失败！');
          }

          setTimeout(function () {
            this_.getStationList();
          }, 200);
          this_.stationForm = {
            id: null,
            name: null,
            address: null,
            longitude: null,
            latitude: null,
            city: null,
            customer: null,
            commissioningTime: null,
            afterSalesCall: null,
            powerSupply: null,
            classification: '1',
            operatingModel: '1',
            description: ''
          };
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }

          this_.stationForm = {
            id: null,
            name: null,
            address: null,
            longitude: null,
            latitude: null,
            city: null,
            customer: null,
            commissioningTime: null,
            afterSalesCall: null,
            powerSupply: null,
            classification: '1',
            operatingModel: '1',
            description: ''
          };
        });
      }
    },
    beforeCloseHandel: function beforeCloseHandel() {
      var this_ = this;
      this_.operationType = 0;
      this_.FormVisible = false;
      this_.stationForm = {
        id: null,
        name: null,
        address: null,
        longitude: null,
        latitude: null,
        city: null,
        customer: null,
        commissioningTime: null,
        afterSalesCall: null,
        powerSupply: null,
        classification: '1',
        operatingModel: '1',
        description: ''
      };
    },
    add: function add() {
      var this_ = this;

      if (this_.mp == null) {
        this_.initMap(null);
      }

      this_.mp.clearOverlays();
      this_.operationType = 1;
      this_.dialogTitle = '新建电站';
      this_.FormVisible = true;
    },
    edit: function edit() {
      var this_ = this;
      this_.operationType = 2;
      this_.dialogTitle = '更新电站';

      if (this_.selectedRows.length != 1) {
        this.$message.warning('您还没有选择要更新的电站，请先选择！');
        return;
      }

      this_.stationForm = {
        id: this_.selectedRows[0].id,
        name: this_.selectedRows[0].name,
        address: this_.selectedRows[0].address,
        city: this_.selectedRows[0].city,
        customer: this_.selectedRows[0].customer,
        longitude: this_.selectedRows[0].longitude,
        latitude: this_.selectedRows[0].latitude,
        commissioningTime: moment(this_.selectedRows[0].commissioningTime, "YYYY-MM-DD HH:mm:ss"),
        afterSalesCall: this_.selectedRows[0].afterSalesCall,
        powerSupply: this_.selectedRows[0].powerSupply,
        classification: this_.selectedRows[0].classification + '',
        operatingModel: this_.selectedRows[0].operatingModel + '',
        description: this_.selectedRows[0].description
      };
      this_.FormVisible = true;
      this_.initMap(this_.stationForm);
      this_.mp.clearOverlays();
    },
    del: function del() {
      var this_ = this;
      var id = null;

      if (this.FormVisible) {
        return;
      }

      if (this_.selectedRows.length != 1) {
        this.$message.warning('您还没有选择要删除的电站，请先选择！');
        return;
      } else {
        id = this_.selectedRows[0].id;
      }

      this_.$confirm({
        title: "删除设备",
        content: '此操作将永久删除选中的电站, 是否继续?',
        okText: '确认',
        cancelText: '取消',
        onOk: function onOk() {
          axios({
            method: "get",
            url: "/emind/config/equipment/list/0/" + id
          }).then(function (d) {
            //
            if (!d) {
              this_.$message.error('登录超时，请重新登录', 2, function () {});
              return;
            }

            if (d.data.data > 0) {
              this_.$message.warning('该电站还包含有设备，不能删除！');
              return;
            } else {
              axios({
                method: "delete",
                url: "/emind/config/station/deletion/" + id
              }).then(function (d) {
                //
                if (!d) {
                  this_.$message.error('登录超时，请重新登录', 2, function () {});
                  return;
                }

                if (d.data.code === "0") {
                  this_.cacheStations();
                  this_.$message.success('电站删除成功');
                } else {
                  this_.$message.error('请求出错', 2, function () {});
                }

                setTimeout(function () {
                  this_.getStationList();
                }, 200);
                this_.clearRowSelection();
              }).catch(function (error) {
                if (error.message != '') {
                  this_.$message.error(error.message, 2, function () {});
                  return;
                }
              });
            }
          }).catch(function (error) {
            if (error.message != '') {
              this_.$message.error(error.message, 2, function () {});
              return;
            }
          });
        }
      });
    }
  }
};